<template>
  <section
    class="helper relative mx-auto flex w-full flex-col bg-[var(--theme-primary-color,#FF4F00)] px-[24px] py-[32px] lg:min-h-[390px] lg:p-[85px] lg:py-24 2xl:min-h-[490px]"
  >
    <div
      class="detail pointer-events-none absolute right-0 top-[-100px] z-10 h-[207px] w-[40vw] lg:right-[-175px] lg:top-[-80px] lg:h-[414px] lg:w-[575px] 2xl:right-[-375px] 2xl:w-[675px]"
    >
      <div></div>
    </div>
    <hgroup class="flex flex-col items-start gap-5 self-stretch text-neutral-low-dark">
      <h2 class="text-6xl font-bold leading-none max-lg:mb-8 lg:text-[2.92rem]" v-html="helper.title" />
      <h3 class="text-lg" v-html="helper.subtitle" />
    </hgroup>
    <Button
      v-if="helper.cta.show"
      class="z-10 mr-auto mt-12 rounded-[6px] border !border-[var(--button-secondary-borderColor,#DFE7EF)] !bg-[var(--button-secondary-backgroundt,#FFF)] !text-[var(--button-secondary-color,#000000)]"
      @click="redirectToWhatsApp(helper.cta)"
    >
      <i v-if="helper.cta.icon" class="mr-2 !text-2xl" :class="helper.cta.icon" />
      {{ helper.cta.label }}
    </Button>
  </section>
</template>

<script setup>
import { useUserStore } from "@store/UserStore";
import { computed } from "vue";

import { trackButton } from "@/utils/analitycsHelper/button";
import Tracker from "@/utils/Tracker/Tracker";

const props = defineProps({
  helper: {
    type: Object,
    default: () => ({}),
  },
});
const helper = computed(() => props.helper);

const UserStore = useUserStore();

const redirectToWhatsApp = async (cta) => {
  const nameEvent = "button_" + cta.label.replace(/ /g, "-").toLowerCase();

  Tracker.getInstance().track(nameEvent, trackButton(cta.label));

  window.open(cta.url, cta.target);
};
</script>

<style lang="scss" scoped>
@import "./../../../styles/themes/tailwind/tailwind-light/variables";
.detail {
  right: -40px;
  @media screen and (max-width: 1024px) {
    overflow: hidden;
  }
  > div,
  &:before,
  &:after {
    content: "";
    display: block;
    background-color: $primaryColor;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    position: absolute;
    height: 62px;
    width: 85%;
    right: 0;
    @media screen and (min-width: 1024px) {
      height: 125px;
    }
  }
  > div {
    background-color: #fff;
    top: 0;
    width: 70%;
  }
  &:before {
    top: 72px;
    background-color: #fff;
    @media screen and (min-width: 1024px) {
      top: 145px;
    }
  }
  &:after {
    background-color: #fff;
    bottom: 0;
    width: 70%;
  }
}
</style>
